import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { ISnackbar } from '../models/snackbar.models';

@Injectable({
	providedIn: 'root',
})
export class SnackbarService {
	private $snackbar: BehaviorSubject<ISnackbar>;

	constructor() {
		this.$snackbar = new BehaviorSubject<ISnackbar>(null);
	}

	public getSnackBar(): Observable<ISnackbar> {
		return this.$snackbar.asObservable();
	}

	public setSnackBar(value: ISnackbar): void {
		this.$snackbar.next(value);
	}

	public show(label: string) {
		this.$snackbar.next({ show: true, text: label });
		timer(5000).subscribe(() => this.hide);
	}

	public hide() {
		this.$snackbar.next({ show: false, text: '' });
	}
}
