import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CookieService } from '../services/cookie.service';
import { UserInfoService } from '../services/user-info.service';

@Injectable()
export class AuthGuard {
	constructor(
		public router: Router,
		private cookieService: CookieService,
		private userInfoService: UserInfoService,
	) {}

	canActivate(): boolean {
		const accessToken = localStorage.getItem('access_token');
		if (accessToken) {
			this.userInfoService.userIsSuperAdmin() ? this.router.navigate(['back']) : this.router.navigate(['front']);
		}
		return true;
	}
}

export const isLogged: CanActivateFn = () => {
	return inject(AuthGuard).canActivate();
};
