import { ITablePagination, IToolbar } from '@tecalisdev/infinity';

export interface IMaster {
	postsells_reasons: Array<IMasterElement>;
	operational_status: Array<IMasterElement>;
	portability_status: Array<IMasterElement>;
	billing: {
		deadlines: Array<IMasterElement>;
		pay_types: Array<IMasterElement>;
		record_statuses: Array<IMasterElement>;
	};
	barrings: {
		call_118xx: Array<IMasterElement>;
	};
	document_types: Array<IMasterElement>;
	donor_operators: Array<IMasterElement>;
	permissions: Array<IMasterElement>;
	permission_categories: Array<IMasterElement>;
	provinces: Array<IMasterElement>;
	provinces_fi: Array<IMasterElement>;
	report_status: Array<IMasterElement>;
	status: Array<IMasterElement>;
	role: Array<IRoleMasterElement>;
	isps: Array<IMasterElement>;
	lock_types: Array<IMasterElement>;
	product_status: Array<IMasterElement>;
	services: Array<IMasterElement>;
	street_types: Array<IMasterElement>;
	cto_tests: Array<IMasterElement>;
}

export interface IMasterElement {
	id?: string;
	code: string;
	label: string;
	name?: string;
	description?: string;
}

export interface IRoleMasterElement {
	code: string;
	description: string;
	name: string;
	role_id: string;
}

export interface IMasterResponse {
	info: IMaster;
	request_id: string;
	result: string;
}

export interface ISaveToolbar {
	toolbar: IToolbar;
	from: string;
	to: string;
	pagination: ITablePagination;
	tab?: any;
}

export interface IToolbarFilterAndPagination {
	pagination: boolean;
	filter: boolean;
}

export type TResultRequest = 'ok' | 'ko';

export const CHART_COLORS = ['#80DBB7', ' #412BA8', '#FF5A3E', '#E6EBF4', '#00A5AA', '#223268', '#FCEB8F', '#FFB048'];

export const PHONE_PREFIX = [
	{
		code: '01',
		prefix: ['945', '845'],
	},
	{
		code: '02',
		prefix: ['967', '867'],
	},
	{
		code: '03',
		prefix: ['965', '966', '865'],
	},
	{
		code: '04',
		prefix: ['950', '850'],
	},
	{
		code: '33',
		prefix: ['984', '985', '884'],
	},
	{
		code: '05',
		prefix: ['920', '820'],
	},
	{
		code: '06',
		prefix: ['924', '824'],
	},
	{
		code: '08',
		prefix: ['93', '83'],
	},
	{
		code: '09',
		prefix: ['947', '847'],
	},
	{
		code: '10',
		prefix: ['927', '827'],
	},
	{
		code: '11',
		prefix: ['956', '856'],
	},
	{
		code: '39',
		prefix: ['942', '842'],
	},
	{
		code: '12',
		prefix: ['964', '864'],
	},
	{
		code: '51',
		prefix: ['956', '856'],
	},
	{
		code: '13',
		prefix: ['926', '826'],
	},
	{
		code: '14',
		prefix: ['957', '857'],
	},
	{
		code: '16',
		prefix: ['969', '869'],
	},
	{
		code: '17',
		prefix: ['972', '872'],
	},
	{
		code: '18',
		prefix: ['958', '858'],
	},
	{
		code: '19',
		prefix: ['949', '849'],
	},
	{
		code: '20',
		prefix: ['943', '843'],
	},
	{
		code: '21',
		prefix: ['959', '859'],
	},
	{
		code: '22',
		prefix: ['974', '874'],
	},
	{
		code: '07',
		prefix: ['971', '871'],
	},
	{
		code: '23',
		prefix: ['953', '853'],
	},
	{
		code: '15',
		prefix: ['981', '881'],
	},
	{
		code: '26',
		prefix: ['941', '841'],
	},
	{
		code: '35',
		prefix: ['928', '828'],
	},
	{
		code: '24',
		prefix: ['987', '887'],
	},
	{
		code: '25',
		prefix: ['973', '873'],
	},
	{
		code: '27',
		prefix: ['982', '882'],
	},
	{
		code: '28',
		prefix: ['91', '81'],
	},
	{
		code: '29',
		prefix: ['951', '952', '851'],
	},
	{
		code: '52',
		prefix: ['951', '952', '851'],
	},
	{
		code: '30',
		prefix: ['968', '868'],
	},
	{
		code: '31',
		prefix: ['948', '848'],
	},
	{
		code: '32',
		prefix: ['988', '888'],
	},
	{
		code: '34',
		prefix: ['979', '879'],
	},
	{
		code: '36',
		prefix: ['986', '886'],
	},
	{
		code: '37',
		prefix: ['923', '823'],
	},
	{
		code: '38',
		prefix: ['922', '822'],
	},
	{
		code: '40',
		prefix: ['921', '821'],
	},
	{
		code: '41',
		prefix: ['954', '955', '854'],
	},
	{
		code: '42',
		prefix: ['975', '875'],
	},
	{
		code: '43',
		prefix: ['977', '877'],
	},
	{
		code: '44',
		prefix: ['978', '878'],
	},
	{
		code: '45',
		prefix: ['925', '825'],
	},
	{
		code: '46',
		prefix: ['960', '961', '962', '963', '860'],
	},
	{
		code: '47',
		prefix: ['983', '883'],
	},
	{
		code: '48',
		prefix: ['944', '946', '846'],
	},
	{
		code: '49',
		prefix: ['980', '880'],
	},
	{
		code: '50',
		prefix: ['976', '876'],
	},
];
