import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	private storageKey = 'lang';
	private defaultLanguage = 'es';
	private allowedLanguages = ['es', 'en', 'ca'];

	constructor(private translateService: TranslateService) {}

	getLanguage() {
		const stored = localStorage.getItem(this.storageKey);
		if (!stored || !this.allowedLanguages.includes(stored)) {
			if (this.allowedLanguages.includes(navigator.language)) {
				return navigator.language;
			}
			return this.defaultLanguage;
		}
		return stored;
	}

	setLanguage(lang: string) {
		if (!this.allowedLanguages.includes(lang)) {
			console.log('lang ' + lang + ' is not in the allowed lang list');
			return;
		}
		localStorage.setItem(this.storageKey, lang);
		this.translateService.use(lang);
	}

	autoResolveLanguage() {
		this.setLanguage(this.getLanguage());
	}
}
