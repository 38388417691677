import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';

export class ApiService {
	protected auth: AuthService;
	protected http: HttpClient;

	constructor(auth: AuthService, http: HttpClient) {
		this.auth = auth;
		this.http = http;
	}

	public getPublicHeaders(): HttpHeaders {
		return new HttpHeaders({
			'x-api-key': '177c535e-ef6d-4290-9ed4-2dce675e4ec3',
		});
	}

	public getHeaders(): HttpHeaders {
		return new HttpHeaders({
			Authorization: 'Bearer ' + this.auth.getToken(),
		});
	}

	public getHeadersCsv(): HttpHeaders {
		return new HttpHeaders({
			Authorization: 'Bearer ' + this.auth.getToken(),
			Accept: 'application/csv',
		});
	}
	public getHeadersFormData(): HttpHeaders {
		return new HttpHeaders({
			Authorization: 'Bearer ' + this.auth.getToken(),
		});
	}
}
