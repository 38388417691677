import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from './cookie.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private dialogTokenHasExpired: any = null;

	constructor(
		private router: Router,
		private cookieService: CookieService,
	) {}

	private parseJwt(token: any): any {
		try {
			const base64Url = token.split('.')[1];
			const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			const jsonPayload = decodeURIComponent(
				atob(base64)
					.split('')
					.map((c) => {
						return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join(''),
			);

			return JSON.parse(jsonPayload);
		} catch (error) {
			return false;
		}
	}

	private checkIfTokenHasExpired(): boolean {
		const access_token: string | null = localStorage.getItem('access_token');

		if (access_token && access_token !== '') {
			const jwt = this.parseJwt(access_token);
			if (!jwt) {
				return true;
			}
			const exp = jwt.exp * 1000;

			return new Date().getTime() >= exp;
		}

		return true;
	}

	public getToken(): any {
		if (this.checkIfTokenHasExpired()) {
			console.warn('JWT expired');
			if (this.dialogTokenHasExpired == null) {
				this.dialogTokenHasExpired = true;
				this.logout();
			}
			return;
		}
		return localStorage.getItem('access_token');
	}

	public logout(): void {
		localStorage.removeItem('role');
		localStorage.removeItem('access_token');
		this.router.navigate(['login']);
	}
}
