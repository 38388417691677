<ng-container *ngIf="loading | async as loader">
    <tec-spinner *ngIf="loader.show" img="assets/images/spinner.svg" [show]="loader.show" [text]="loader.text"
        [opaque]="loader.opaque" [watermark]="loader.watermark"></tec-spinner>
</ng-container>

<ng-container *ngIf="customLoading | async as customLoader">
    <tec-spinner *ngIf="customLoader.show" img="assets/images/spinner.svg" [show]="customLoader.show"
        [text]="customLoader.text" [opaque]="customLoader.opaque" [watermark]="customLoader.watermark"></tec-spinner>
</ng-container>

<ng-container *ngIf="snackbar | async as snackBar">
    <tec-snackbar [show]="snackBar?.show" [label]="snackBar?.text" (close)="snackbarService.hide()"></tec-snackbar>
</ng-container>

<ng-container *ngIf="modalConfirmation | async as confirmation">
    <tec-modal [isVisible]="confirmation?.show" type="confirmation"
        [buttonSecondaryLabel]="confirmation?.buttonSecondaryLabel"
        [buttonPrimaryLabel]="confirmation?.buttonPrimaryLabel" [buttonPrimaryStyle]="confirmation?.buttonPrimaryStyle"
        (closeEvent)="dialogConfirmationService.setCloseDialogConfirmation($event)">
        <ng-container body>
            <tec-tag-icon [size]="'default'" [icon]="confirmation.icon"
                [status]="confirmation.iconStatus"></tec-tag-icon>
            <div class="body__text">
                <h4 [innerHTML]="confirmation?.title"></h4>
                <span [innerHTML]="confirmation?.description"></span>
            </div>
        </ng-container>
    </tec-modal>
</ng-container>

<router-outlet></router-outlet>