import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./modules/authn/authn.module').then((m) => m.AuthnModule),
	},
	{
		path: 'front',
		loadChildren: () => import('./modules/frontoffice/frontoffice.module').then((m) => m.FrontofficeModule),
	},
	{
		path: 'back',
		loadChildren: () => import('./modules/backoffice/backoffice.module').then((m) => m.BackofficeModule),
	},
	{
		path: 'back/panel',
		redirectTo: 'back',
	},
	{ path: '', redirectTo: '', pathMatch: 'full' },
	{ path: '**', redirectTo: '' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes), TranslateModule],
	exports: [RouterModule],
})
export class AppRoutingModule {}
