import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { GridShortcutsDirective } from '../core/directives/grid-shortcuts.directive';
import {
	AlertComponent,
	AvatarComponent,
	BreadcrumbComponent,
	ButtonCollapseComponent,
	ButtonIconComponent,
	ButtonComponent,
	CardComponent,
	CardAccessComponent,
	CheckboxComponent,
	ChartComponent,
	ChartLineComponent,
	ChartBarHorizontalComponent,
	ChartBarComponent,
	ChartPieComponent,
	ChipsComponent,
	CodeInputComponent,
	CollapseComponent,
	CommentsComponent,
	ConversationComponent,
	PaginationComponent,
	ProgressComponent,
	AuthLayoutComponent,
	BaseComponent,
	CopyTextDirective,
	CreateLayoutComponent,
	DatepickerComponent,
	AsideLayoutComponent,
	DetailLayoutComponent,
	DividerComponent,
	EmptyStateComponent,
	FooterComponent,
	FormGroupComponent,
	IconComponent,
	InputComponent,
	InputSearchComponent,
	InputQuantityComponent,
	ItemComponent,
	ItemGroupComponent,
	LinkComponent,
	ListDetailComponent,
	ListLayoutComponent,
	ConfirmationComponent,
	MenuComponent,
	ModalComponent,
	ProfileComponent,
	RateAlertComponent,
	RateStatusComponent,
	SelectComponent,
	SidenavComponent,
	SnackbarComponent,
	SpinnerComponent,
	StatsComponent,
	StepperProgressComponent,
	SummaryComponent,
	SwitchComponent,
	TableComponent,
	TabsComponent,
	TagComponent,
	TagIconComponent,
	TagImageComponent,
	TextareaComponent,
	TileComponent,
	ToggleComponent,
	ToolbarComponent,
	TopnavComponent,
	UploadComponent,
	WatermarkComponent,
	TextEditorComponent,
	CopyTextComponent,
	BadgeComponent,
	TitleGroupComponent,
	CardProductComponent,
	CartComponent,
	MediaActionComponent,
	ExpandableDescriptionComponent,
	DashboardComponent,
} from '@tecalisdev/infinity';
import { AutocompleteOffDirective } from '../core/directives/autocomplete-off.directive';

@NgModule({
	imports: [
		AlertComponent,
		AvatarComponent,
		BadgeComponent,
		BreadcrumbComponent,
		ButtonCollapseComponent,
		ButtonIconComponent,
		ButtonComponent,
		CardComponent,
		CardAccessComponent,
		CardProductComponent,
		CartComponent,
		CheckboxComponent,
		ChartComponent,
		ChartLineComponent,
		ChartBarHorizontalComponent,
		ChartBarComponent,
		ChartPieComponent,
		ChipsComponent,
		CodeInputComponent,
		CollapseComponent,
		CommentsComponent,
		ConversationComponent,
		CopyTextComponent,
		DatepickerComponent,
		DividerComponent,
		EmptyStateComponent,
		FooterComponent,
		FormGroupComponent,
		IconComponent,
		InputComponent,
		InputSearchComponent,
		InputQuantityComponent,
		ItemComponent,
		ItemGroupComponent,
		LinkComponent,
		ListDetailComponent,
		TitleGroupComponent,
		ModalComponent,
		MenuComponent,
		MediaActionComponent,
		RateAlertComponent,
		RateStatusComponent,
		StatsComponent,
		StepperProgressComponent,
		SummaryComponent,
		SidenavComponent,
		SnackbarComponent,
		SpinnerComponent,
		SelectComponent,
		SwitchComponent,
		TabsComponent,
		TableComponent,
		TagComponent,
		TagIconComponent,
		TagImageComponent,
		TextareaComponent,
		TextEditorComponent,
		TileComponent,
		ToolbarComponent,
		TopnavComponent,
		UploadComponent,
		WatermarkComponent,
		PaginationComponent,
		ProgressComponent,
		ProfileComponent,
		ToggleComponent,
		CopyTextComponent,
		ExpandableDescriptionComponent,

		// Layout
		AuthLayoutComponent,
		BaseComponent,
		DetailLayoutComponent,
		AsideLayoutComponent,
		CreateLayoutComponent,
		ListLayoutComponent,
		ConfirmationComponent,
		DashboardComponent,

		// Directive
		CopyTextDirective,
		GridShortcutsDirective,
		AutocompleteOffDirective,

		// Modules
		MatNativeDateModule,
		MatDatepickerModule,
	],
	exports: [
		AlertComponent,
		AvatarComponent,
		BadgeComponent,
		BreadcrumbComponent,
		ButtonCollapseComponent,
		ButtonIconComponent,
		ButtonComponent,
		CardComponent,
		CardAccessComponent,
		CardProductComponent,
		CartComponent,
		CheckboxComponent,
		ChartComponent,
		ChartLineComponent,
		ChartBarHorizontalComponent,
		ChartBarComponent,
		ChartPieComponent,
		ChipsComponent,
		CodeInputComponent,
		CollapseComponent,
		CommentsComponent,
		ConversationComponent,
		CopyTextComponent,
		ExpandableDescriptionComponent,
		DatepickerComponent,
		DividerComponent,
		EmptyStateComponent,
		FooterComponent,
		FormGroupComponent,
		IconComponent,
		InputComponent,
		InputSearchComponent,
		InputQuantityComponent,
		ItemComponent,
		ItemGroupComponent,
		LinkComponent,
		ListDetailComponent,
		TitleGroupComponent,
		ModalComponent,
		MenuComponent,
		MediaActionComponent,
		RateAlertComponent,
		RateStatusComponent,
		StatsComponent,
		StepperProgressComponent,
		SummaryComponent,
		SidenavComponent,
		SnackbarComponent,
		SpinnerComponent,
		SwitchComponent,
		SelectComponent,
		TabsComponent,
		TableComponent,
		TagComponent,
		TagIconComponent,
		TagImageComponent,
		TextareaComponent,
		TextEditorComponent,
		TileComponent,
		ToolbarComponent,
		TopnavComponent,
		UploadComponent,
		WatermarkComponent,
		PaginationComponent,
		ProgressComponent,
		ProfileComponent,
		ToggleComponent,
		CopyTextComponent,

		// Layout
		AuthLayoutComponent,
		BaseComponent,
		DetailLayoutComponent,
		AsideLayoutComponent,
		CreateLayoutComponent,
		ListLayoutComponent,
		ConfirmationComponent,
		DashboardComponent,

		// Directive
		CopyTextDirective,
		GridShortcutsDirective,
		AutocompleteOffDirective,

		// Modules
		MatNativeDateModule,
		MatDatepickerModule,
	],
	providers: [],
})
export class InfinityModule {}
