import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {
	IMaster,
	IMasterElement,
	IMasterResponse,
	IRoleMasterElement,
	ISaveToolbar,
	IToolbarFilterAndPagination,
	PHONE_PREFIX,
} from '../models/master.models';
import { ISelect, ITagStatus } from '@tecalisdev/infinity';
import { Status } from '../models/app.models';

@Injectable({
	providedIn: 'root',
})
export class MasterService extends ApiService {
	private $master: BehaviorSubject<IMaster> = new BehaviorSubject<IMaster>(null);
	private preToolbar: ISaveToolbar;
	private $roleMaster: BehaviorSubject<IRoleMasterElement> = new BehaviorSubject<IRoleMasterElement>(null);
	private unsavedChanges = false;
	private previousUrl: string;

	constructor(auth: AuthService, http: HttpClient) {
		super(auth, http);
	}

	public getToolbar(): ISaveToolbar {
		return this.preToolbar;
	}

	public setToolbar(value: ISaveToolbar): void {
		this.preToolbar = value;
	}

	setPreviousUrl(previousUrl: string) {
		this.previousUrl = previousUrl;
	}

	getPreviousUrl(): string {
		return this.previousUrl;
	}

	public getMaster(): Observable<IMaster> {
		return this.$master.asObservable();
	}

	public setMaster(value: IMaster): void {
		this.$master.next(value);
	}

	public getRoleMaster(): Observable<any> {
		return this.$roleMaster.asObservable();
	}

	public setRoleMaster(value: any): void {
		this.$roleMaster.next(value);
	}

	public getMasterFromService(): Observable<IMasterResponse> {
		return this.http.get<IMasterResponse>(environment.apiUrl + `/master`, { headers: this.getHeaders() });
	}

	public getRoleMasterFromService(): Observable<any> {
		return this.http.get<any>(environment.apiUrl + `/roles/master`, { headers: this.getHeaders() });
	}

	public getUuid(): Observable<any> {
		return this.http.get<any>(environment.apiUrl + `/uuid`, { headers: this.getHeaders() });
	}

	public downloadList(payload: any): Observable<any> {
		return this.http.post(environment.apiUrl + `/download-list`, payload, {
			headers: this.getHeadersFormData(),
			responseType: 'blob',
			observe: 'response',
		});
	}

	public getProvinces(): Array<ISelect> {
		return (
			this.$master.value?.provinces?.map((province: IMasterElement) => ({
				id: province.code,
				label: province.label,
				value: PHONE_PREFIX.find((prefix) => prefix.code === province.code).prefix,
			})) || null
		);
	}

	public getNameProvince(name: string): string {
		const province: Array<ISelect> = this.getProvinces();
		const found = province.find((state: ISelect) => state.id === name);
		return found ? found.label : '';
	}

	public getProvincesFi(): Array<ISelect> {
		return (
			this.$master.value?.provinces_fi?.map((province: IMasterElement) => ({
				id: province.code,
				label: province.label,
			})) || null
		);
	}

	public getNameProvinceFi(name: string): string {
		const province: Array<ISelect> = this.getProvincesFi();
		const found = province.find((state: ISelect) => state.id === name);
		return found ? found.label : '';
	}

	public getRole(): Array<ISelect> {
		return (
			this.$master.value?.role?.map((role: IRoleMasterElement) => ({ id: role.role_id, label: role.name })) ||
			null
		);
	}

	public getStatus(): Array<ISelect> {
		return (
			this.$master.value?.status?.map((state: IMasterElement) => ({ id: state.code, label: state.label })) || null
		);
	}

	public getNameStatus(name: string): string {
		const status: Array<ISelect> = this.getStatus();
		const found = status.find((state: ISelect) => state.id === name);
		return found ? found.label : '';
	}

	public getStyleStatus(status: string): ITagStatus {
		const style = {
			active: () => 'success',
			pending: () => 'warning',
			deactive: () => 'disable',
		};

		return style[status] ? style[status]() : 'default';
	}

	public getReportStatus(): Array<ISelect> {
		return (
			this.$master.value?.report_status?.map((state: IMasterElement) => ({
				id: state.code,
				label: state.label,
			})) || null
		);
	}

	public getNameReportStatus(name: string): string {
		const status: Array<ISelect> = this.getReportStatus();
		const found = status.find((state: ISelect) => state.id === name);
		return found ? found.label : '';
	}

	public getDocumentTypes(): Array<ISelect> {
		return (
			this.$master.value?.document_types?.map((document_type: IMasterElement) => ({
				id: document_type.id,
				label: document_type.label,
				value: document_type.code,
			})) || null
		);
	}

	public getNameDocumentTypes(code: string): string {
		const status: Array<ISelect> = this.getDocumentTypes();
		const found = status.find((state: ISelect) => state.id === code);
		return found ? found.label : '';
	}

	public getPostsellsReasons(): Array<ISelect> {
		return (
			this.$master.value?.postsells_reasons?.map((reason: IMasterElement) => ({
				id: reason.code,
				label: reason.label,
			})) || null
		);
	}

	public getNamePostsellsReasons(code: string): string {
		const status: Array<ISelect> = this.getPostsellsReasons();
		const found = status.find((state: ISelect) => state.id === code);
		return found ? found.label : '';
	}

	public getPermissionCategories(): Array<ISelect> {
		return (
			this.$master.value?.permission_categories?.map((category: IMasterElement) => ({
				id: category.code,
				label: category.label,
			})) || null
		);
	}

	public getFullLockTypes(): IMasterElement[] {
		return this.$master.value.lock_types;
	}

	public getLockTypesData(): Array<ISelect> {
		return [
			{ id: 'document', label: 'Documento' },
			{ id: 'email', label: 'Email' },
			{ id: 'phone', label: 'Teléfono' },
		];
	}

	public getNamePermissionCategory(category: string): string {
		const status: Array<ISelect> = this.getPermissionCategories();
		const found = status.find((state: ISelect) => state.id === category);
		return found ? found.label : '';
	}

	public ellipsis(inputString: string, truncateLength = 4, lastLength = 2, maxLength = 6): string {
		if (!inputString) return '';
		if (inputString.length <= maxLength) {
			return inputString;
		} else {
			const truncatedString = inputString.substring(0, truncateLength);
			const lastTwoLetters = inputString.substring(inputString.length - lastLength);
			return truncatedString + '...' + lastTwoLetters;
		}
	}

	public getIsps(): Array<ISelect> {
		return this.$master.value?.isps?.map((isp: IMasterElement) => ({ id: isp.code, label: isp.name })) || null;
	}

	public getLockTypes(): Array<ISelect> {
		return (
			this.$master.value?.lock_types?.map((lock: IMasterElement) => ({ id: lock.code, label: lock.label })) ||
			null
		);
	}

	public getBillingPayTypes(): Array<ISelect> {
		return (
			this.$master.value?.billing.pay_types?.map((state: IMasterElement) => ({
				id: state.code,
				label: state.label,
			})) || null
		);
	}

	public getNameBillingPayTypes(id: string): string {
		const status: Array<ISelect> = this.getBillingPayTypes();
		const found = status.find((state: ISelect) => state.id === id);
		return found ? found.label : '';
	}

	public getBillingDeadlines(): Array<ISelect> {
		return (
			this.$master.value?.billing.deadlines?.map((state: IMasterElement) => ({
				id: state.code,
				label: state.label,
			})) || null
		);
	}

	public getNameBillingDeadlines(id: string): string {
		const status: Array<ISelect> = this.getBillingDeadlines();
		const found = status.find((state: ISelect) => state.id === id);
		return found ? found.label : '';
	}

	public getBillingRecordStatus(): Array<ISelect> {
		return (
			this.$master.value?.billing.record_statuses?.map((state: IMasterElement) => ({
				id: state.code,
				label: state.label,
			})) || null
		);
	}

	public getNameBillingRecordStatus(id: string): string {
		const status: Array<ISelect> = this.getBillingRecordStatus();
		const found = status.find((state: ISelect) => state.id === id);
		return found ? found.label : '';
	}

	public getNameBillingRecordStatusStyle(status: string): ITagStatus {
		const style = {
			cycle_in_process: () => 'disabled',
			pending_proforma: () => 'warning',
			pending_validation: () => 'warning',
			pending_invoice: () => 'warning',
			billed: () => 'success',
		};

		return style[status] ? style[status]() : 'default';
	}

	public getBillingProformaStatus(): Array<ISelect> {
		return [
			{ id: 'pending', label: 'Pte. validar' },
			{ id: 'sent', label: 'Enviado' },
			{ id: 'valid', label: 'Validado' },
			{ id: 'invalid', label: 'No validado' },
			{ id: 'billed', label: 'Facturado' },
		];
	}

	public getNameBillingProformaStatus(id: string): string {
		const status: Array<ISelect> = this.getBillingProformaStatus();
		const found = status.find((state: ISelect) => state.id === id);
		return found ? found.label : '';
	}

	public getStyleBillingProformaStatus(status: string): ITagStatus {
		const style = {
			pending: () => 'warning',
			sent: () => 'default',
			valid: () => 'success',
			invalid: () => 'error',
			billed: () => 'success',
		};

		return style[status] ? style[status]() : 'default';
	}

	public getBillingInvoiceStatus(): Array<ISelect> {
		return [
			{ id: 'unpaid', label: 'Impagada' },
			{ id: 'paid', label: 'Pagado' },
			{ id: 'pending', label: 'Pendiente' },
			{ id: 'rectified', label: 'Rectificada' },
		];
	}

	public getNameBillingInvoiceStatus(id: string): string {
		const status: Array<ISelect> = this.getBillingInvoiceStatus();
		const found = status.find((state: ISelect) => state.id === id);
		return found ? found.label : '';
	}

	public getStyleBillingInvoiceStatus(status: string): ITagStatus {
		const style = {
			unpaid: () => 'error',
			paid: () => 'success',
			pending: () => 'warning',
			rectified: () => 'disabled',
		};

		return style[status] ? style[status]() : 'default';
	}

	public getBarringsCall118xx(): Array<ISelect> {
		return (
			this.$master.value?.barrings.call_118xx?.map((state: IMasterElement) => ({
				id: state.code,
				label: state.label,
			})) || null
		);
	}

	public getOptionBarringsCall118xx(idOrName: string) {
		const status: Array<ISelect> = this.getBarringsCall118xx();
		let found = status.find((state: ISelect) => state.id === idOrName);
		if (!found) {
			found = status.find((state: ISelect) => state.label === idOrName);
		}
		return found ? found : null;
	}

	public getNameBarringsCall118xx(id: string): string {
		const status: Array<ISelect> = this.getBarringsCall118xx();
		const found = status.find((state: ISelect) => state.id === id);
		return found ? found.label : '';
	}

	public getNameProductStatus(productId: string): string {
		const status: Array<ISelect> = this.getProductStatus();
		const found = status.find((state: ISelect) => state.id === productId);
		return found ? found.label : '';
	}

	public getProductStatus(): Array<ISelect> {
		return (
			this.$master.value?.product_status?.map((product: IMasterElement) => ({
				id: product.code,
				label: product.label,
			})) || null
		);
	}

	public getNameOperationalStatus(productId: string): string {
		const status: Array<ISelect> = this.getOperationalStatus();
		const found = status.find((state: ISelect) => state.id === productId);
		return found ? found.label : '';
	}

	public getOperationalStatus(): Array<ISelect> {
		return (
			this.$master.value?.operational_status?.map((product: IMasterElement) => ({
				id: product.code,
				label: product.label,
			})) || null
		);
	}

	public getNamePortabilityStatus(productId: string): string {
		const status: Array<ISelect> = this.getPortabilityStatus();
		const found = status.find((state: ISelect) => state.id === productId?.split(' ')[0]);
		return found ? found.label : '';
	}

	public getPortabilityStatus(): Array<ISelect> {
		return (
			this.$master.value?.portability_status?.map((product: IMasterElement) => ({
				id: product.code,
				label: product.label,
			})) || null
		);
	}

	public getColorProductStatus(status: string): ITagStatus {
		const colors = {
			[Status.ACTIVE]: () => 'success',
			[Status.PROVISIONED]: () => 'success',

			[Status.ERROR]: () => 'error',

			[Status.CREATED]: () => 'warning',
			[Status.PENDING_SIM]: () => 'warning',
			[Status.RESERVED]: () => 'warning',
			[Status.EXPIRED]: () => 'warning',

			[Status.ANNULLED]: () => 'disabled',
			[Status.TERMINATED]: () => 'disabled',
			[Status.CANCELLED]: () => 'disabled',
			[Status.SUSPENDED]: () => 'disabled',
		};

		return colors[status] ? colors[status]() : 'default';
	}

	public getProductTypes(): Array<ISelect> {
		return [
			{ id: 'Bundle', label: 'Bundle' },
			{ id: 'Fibra', label: 'Fibra' },
			{ id: 'Móvil', label: 'Móvil' },
		];
	}

	public getNameProductTypes(code: string): string {
		const products: Array<ISelect> = [
			{ id: 'convergent', label: 'Bundle' },
			{ id: 'bundle', label: 'Bundle' },
			{ id: 'ftth', label: 'Fibra' },
			{ id: 'mobile', label: 'Móvil' },
		];
		const found = products.find((state: ISelect) => state.id === code);
		return found ? found.label : '';
	}

	public getDonorOperators(): Array<ISelect> {
		return (
			this.$master.value?.donor_operators?.map((state: IMasterElement) => ({
				id: state.code,
				label: state.label,
			})) || null
		);
	}

	public getNameDonorOperators(name: string): string {
		const status: Array<ISelect> = this.getDonorOperators();
		const found = status.find((state: ISelect) => state.id === name);
		return found ? found.label : '';
	}

	public getStreetTypes(): Array<ISelect> {
		return (
			this.$master.value?.street_types?.map((type: IMasterElement) => ({
				id: type.code,
				label: type.label,
			})) || null
		);
	}

	public getNameStreet(code: string): string {
		const status: Array<ISelect> = this.getStreetTypes();
		const found = status.find((state: ISelect) => state.id === code);
		return found ? found.label : '';
	}

	public getServices(): Array<ISelect> {
		return (
			this.$master.value?.services?.map((service: IMasterElement) => ({
				id: service.code,
				label: service.name,
			})) || null
		);
	}

	public getDiagnosticTest(): Array<ISelect> {
		// const cto_tests = [
		// 	{ id: 'TST_FTTH_DATOS', label: 'Test de consulta de datos', value: 'sync' },
		// 	{ id: 'TST_FTTH_CONF_EMP', label: 'Test de configuración', value: 'sync' },
		// 	{ id: 'TST_FTTH_OSTATE', label: 'Consulta Estado Óptico', value: 'sync' },
		// 	{ id: 'TST_RAMA_BS', label: 'Test de RAMA BitStream', value: 'sync' },
		// 	{ id: 'TST_FTTH_STATE_ONT', label: 'Consulta Estado ONT', value: 'sync' },
		// 	{ id: 'TST_FTTH_RESET_ONT', label: 'Reseteo ONT', value: 'sync' },
		// 	{ id: 'TST_FTTH_ONT_OLT', label: 'Verificación de Configuración ONT en OLT', value: 'sync' },
		// 	{ id: 'TST_ESTADO_PON', label: 'Consulta Estado PON', value: 'sync' },
		// 	{ id: 'TST_FTTH_PON', label: 'Diagnóstico PON', value: 'sync' },
		// ];

		return (
			this.$master.value?.cto_tests?.map((type: IMasterElement) => ({
				id: type.code,
				label: type.label,
			})) || null
		);
	}

	public getDiagnosticTestName(id: string): string {
		const item: Array<ISelect> = this.getDiagnosticTest();
		const found = item.find((state: ISelect) => state.id === id);
		return found ? found.label : '';
	}

	public getBefore6Month(selectDay?: Date): Array<ISelect> {
		const currentMonth = new Date();
		selectDay = selectDay ?? currentMonth;

		const MonthsNames = [
			'Enero',
			'Febrero',
			'Marzo',
			'Abril',
			'Mayo',
			'Junio',
			'Julio',
			'Agosto',
			'Septiembre',
			'Octubre',
			'Noviembre',
			'Diciembre',
		];

		const year = selectDay.getFullYear();
		const month = selectDay.getMonth();

		const lastMonths = [];

		for (let i = 5; i >= 0; i--) {
			let newMonth = month - i;
			let newYear = year;

			while (newMonth < 0) {
				newMonth += 12;
				newYear--;
			}

			lastMonths.push({
				id: `${newYear}-${newMonth < 9 ? '0' + (newMonth + 1) : newMonth + 1}`,
				label: `${MonthsNames[newMonth]} ${newYear}`,
			});
		}
		return lastMonths.reverse();
	}

	get getUnsavedChanges() {
		return this.unsavedChanges;
	}

	setUnsavedChanges(changes) {
		this.unsavedChanges = changes;
	}

	public getMonths(): Array<ISelect> {
		return [
			{ id: '01', label: 'Enero' },
			{ id: '02', label: 'Febrero' },
			{ id: '03', label: 'Marzo' },
			{ id: '04', label: 'Abril' },
			{ id: '05', label: 'Mayo' },
			{ id: '06', label: 'Junio' },
			{ id: '07', label: 'Julio' },
			{ id: '08', label: 'Agosto' },
			{ id: '09', label: 'Septiembre' },
			{ id: '10', label: 'Octubre' },
			{ id: '11', label: 'Noviembre' },
			{ id: '12', label: 'Diciembre' },
		];
	}

	public getMonthById(id): ISelect {
		const status: Array<ISelect> = this.getMonths();
		const found = status.find((state: ISelect) => state.id === id);
		return found ? found : this.getMonths()[0];
	}

	public hasFilterToolbarOrPaginationSelected(tableFilters, toolbar, pagination, from, to, tab?) {
		// Tab la uso en la proforma para saber la tab seleccionada
		const filters = JSON.parse(JSON.stringify(tableFilters));
		let result: IToolbarFilterAndPagination = { pagination: false, filter: false };
		if (filters) {
			delete filters.page;
			delete filters.limit;
			result = { pagination: tableFilters.page > 1, filter: Boolean(Object.entries(filters).length) };
		}
		if (result.filter || result.pagination || tab) {
			this.setToolbar({
				toolbar: result.filter ? toolbar : undefined,
				from: from,
				to: to,
				pagination: result.pagination ? pagination : undefined,
				tab: tab ? tab : undefined,
			});
		}
	}
}
