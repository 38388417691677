import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ILoading } from '../models/loading.models';

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	private showLoading: BehaviorSubject<ILoading>;
	private showCustomLoading: BehaviorSubject<ILoading>;

	constructor() {
		this.showLoading = new BehaviorSubject<ILoading>({ show: false, text: '', watermark: false, opaque: false });
		this.showCustomLoading = new BehaviorSubject<ILoading>({
			show: false,
			text: '',
			watermark: true,
			opaque: true,
		});
	}

	public getShowLoading(): Observable<ILoading> {
		return this.showLoading.asObservable();
	}

	public setShowLoading(value: ILoading): void {
		this.showLoading.next(value);
	}

	public getShowCustomLoading(): Observable<ILoading> {
		return this.showCustomLoading.asObservable();
	}

	public setShowCustomLoading(value: ILoading): void {
		this.showCustomLoading.next(value);
	}

	public show() {
		this.showLoading.next({ show: true, text: '', watermark: false, opaque: false });
	}

	public hide() {
		this.showLoading.next({ show: false, text: '', watermark: false, opaque: false });
	}

	public showCustom(text = '', watermark = true) {
		this.showCustomLoading.next({ show: true, text: text, watermark: watermark, opaque: true });
	}

	public hideCustom() {
		this.showCustomLoading.next({ show: false, text: '', watermark: false, opaque: false });
	}
}
