import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IModalClose, IModalConfirmation } from '../models/modal-confirmation.models';

@Injectable({
	providedIn: 'root',
})
export class DialogConfirmationService {
	private $dialogConfirmation = new BehaviorSubject<IModalConfirmation>(null);
	private $closeDialogConfirmation = new Subject<IModalClose>();

	public getDialogConfirmation(): Observable<IModalConfirmation> {
		return this.$dialogConfirmation.asObservable();
	}

	public setDialogConfirmation(value: IModalConfirmation): void {
		this.$dialogConfirmation.next(value);
	}

	public getCloseDialogConfirmation(): Observable<IModalClose> {
		return this.$closeDialogConfirmation.asObservable();
	}

	public setCloseDialogConfirmation(value: IModalClose): void {
		this.$closeDialogConfirmation.next(value);
	}

	public show(conf: IModalConfirmation): Observable<IModalClose> {
		this.$closeDialogConfirmation = new Subject<IModalClose>();
		this.$dialogConfirmation.next({
			show: true,
			icon: conf?.icon || 'icon-alert',
			iconStatus: conf?.iconStatus || 'default',
			title: conf.title,
			description: conf.description || '',
			buttonPrimaryLabel: conf.buttonPrimaryLabel,
			buttonPrimaryStyle: conf.buttonPrimaryStyle ?? false,
			buttonSecondaryLabel: conf.buttonSecondaryLabel,
		});
		return this.$closeDialogConfirmation.asObservable();
	}

	public hide(): void {
		this.$dialogConfirmation.next({ show: false, title: '' });
		this.$closeDialogConfirmation.unsubscribe();
	}
}
