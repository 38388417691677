import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from './core/services/cookie.service';
import { InfinityModule } from './shared/infinity.module';
import { AuthGuard } from './core/guards/auth-guard';
import { Observable, forkJoin, map } from 'rxjs';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';

export class MultiHttpLoader implements TranslateLoader {
	constructor(
		private http: HttpClient,
		public resources: string[],
	) {}

	getTranslation(lang: string): Observable<any> {
		const requests = this.resources.map((path) => this.http.get(`${path}${lang}.json`));
		return forkJoin(requests).pipe(map((response) => Object.assign({}, ...response)));
	}
}

registerLocaleData(localeEs);
export function HttpLoaderFactory(http: HttpClient) {
	return new MultiHttpLoader(http, [
		'assets/i18n/', // Ruta de la app
		'/i18n/', // Ruta de la librería
	]);
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		InfinityModule,
		MatGoogleMapsAutocompleteModule.forRoot('AIzaSyC7Opl5MZyhe_lDM2L-NGQwm42OxG_dACc'),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	providers: [CookieService, AuthGuard, { provide: LOCALE_ID, useValue: 'es' }],
	bootstrap: [AppComponent],
})
export class AppModule {}
