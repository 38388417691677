import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { LoadingService } from './loading.service';
import { Router } from '@angular/router';
import { IUser, IUserInfo, IUserResponse } from '../models/user-info.models';
import { ISidenav } from '@tecalisdev/infinity';
import { ErrorHandlerService } from '../helpers/error-handler.service';

@Injectable({
	providedIn: 'root',
})
export class UserInfoService extends ApiService {
	private $user: BehaviorSubject<IUserInfo> = new BehaviorSubject<IUserInfo>(null);

	constructor(
		auth: AuthService,
		http: HttpClient,
		private router: Router,
		private loadingService: LoadingService,
		private errorHandlerService: ErrorHandlerService,
	) {
		super(auth, http);
	}

	public getUserInfoService(): Observable<IUserResponse> {
		return this.http.get<IUserResponse>(environment.apiUrl + `/user/info`, { headers: this.getHeaders() });
	}

	public getUserObserver(): Observable<IUserInfo> {
		return this.$user.asObservable();
	}

	public setUserObserver(value: IUserInfo): void {
		this.$user.next(value);
	}

	public getUserInfo() {
		return this.$user.value;
	}

	public getUser() {
		return this.$user?.value?.user;
	}

	public getUserRole() {
		return localStorage.getItem('role');
	}

	public userIsSuperAdmin() {
		return this.getUserRole() === 'super_admin';
	}

	public getUserBackMenu() {
		return this.$user?.value?.menu?.back;
	}

	public getUserFrontMenu() {
		return this.$user?.value?.menu?.front;
	}

	public getUserPermissions() {
		return this.$user?.value?.permissions;
	}

	public setUserInfo() {
		this.loadingService.showCustom('Obteniendo datos del usuario');

		this.getUserInfoService().subscribe({
			next: (response: IUserResponse) => {
				localStorage.setItem('role', response.info.user.role);
				this.setUserObserver({
					user: this.transformerUser(response.info.user),
					permissions: response.info.permissions,
					menu: {
						back: this.transformerUserMenu(response.info.menu.back),
						front: this.transformerUserMenu(response.info.menu.front),
					},
				});
			},
			error: (error) => {
				this.errorHandlerService.handleHttpError(error);
			},
			complete: () => {
				this.userIsSuperAdmin()
					? this.router.navigate(['back']).then(() => this.loadingService.hideCustom())
					: this.router.navigate(['front']).then(() => this.loadingService.hideCustom());
			},
		});
	}

	public permissionAccess(permission: string) {
		const listPermissions = this.getUserPermissions();
		if (listPermissions) {
			return listPermissions[permission] === 'permitir';
		}
		return false;
	}

	public transformerUser(response: IUser): IUser {
		return {
			name: response?.username.replace('.', ' '),
			...response,
		};
	}

	public transformerUserMenu(response: any[]): ISidenav[] {
		return response.map((row) => {
			const option: ISidenav = {
				icon: row.icon,
				label: row.text,
				title: row.title,
				divider: row.divider,
			};
			if (row.url) {
				option.url = `/${row.url}`;
			}
			if (row.children?.length > 0) {
				option.menu = row.children.map((child) => ({
					icon: child.icon,
					label: child.text,
					url: `/${child.url}`,
					divider: child.divider,
				}));
			}
			return option;
		});
	}
}
